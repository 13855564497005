<template>
  <fieldset class="my-4 mx-auto">
    <input
      type="text"
      name="password"
      id="password-input"
      inputmode="numeric"
      minlength="4"
      maxlength="4"
      size="4"
      v-model="pinNumber"
      @change="onPinChange"
    />
  </fieldset>
</template>

<script>
export default {

  data() {
    return {
      pinNumber: "",
    };
  },
  methods: {
    onPinChange() {
      // console.log(this.value)
      this.$emit("blurred", this.pinNumber);
    },
  },
};
</script>

<style scoped>
fieldset {
  color: #555;
  font-family: sans-serif;
  border: none;
  position: relative;
}

fieldset > * {
  display: block;
}

fieldset::after {
  content: "___   ___   ___   ___";
  display: block;
  position: absolute;
  top: 10px;
  /* left: 5px; */
  white-space: pre;
  width: max-content;
  background: white;
  padding: 20px 20px 5px;
  border-radius: 5px;
}

label {
  font-size: 14px;
  margin-bottom: 6px;
}

input#password-input {
  position: relative;
  font-size: 26px;
  z-index: 2;
  border: none;
  background: transparent;
  width: 300px;
  text-indent: 9px;
  letter-spacing: 25.6px;
  font-family: Courier;
  padding: 10px 15px;
}

input#password-input:focus {
  outline: none;
}

span.hint {
  margin-top: 8px;
  font-size: 12px;
  font-style: italic;
}

span.hint::before {
  content: "* ";
}
</style>