<template>
  <div class="w-10/12 lg:w-9/12 text-left font-proximaMedium">
    <h2 class="text-2xl">{{ $t("phone_otp_by_sms_title") }}</h2>
    <h5 class="text-sm my-4">
      {{ $t("phone_otp_by_sms_info_text") }}<br /><span
        class="text-blue"
        v-if="phone_number"
        >{{ phone_number }}</span
      >
    </h5>

    <div>
      <FormError :errors="errorPin" />
      <InputPin @blurred="inputPinVal" />
    </div>
    <Loader class="mt-6 flex justify-center" v-if="showLoader" />
    <SubmitButton
      :title="$t('global_verify')"
      background="bg-primary"
      @click.native="submitForm"
      v-else
    />
  </div>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton.vue";
import InputPin from "../forms/InputPin.vue";
import Loader from "../loader/Loader.vue";
import FormError from "../warning/FormError.vue";

import { getEmailcheckRegistration } from "../../services/emailService";
import { verifyOtp } from "../../services/smsService";

export default {
  components: {
    SubmitButton,
    InputPin,
    Loader,
    FormError,
  },
  data() {
    return {
      pinNumber: "",
      errorPin: [],
      pinReq: true,
      showLoader: false,
      email_address: "",
      phone_number: "",
      provider: "",
      role: "",
      type: "",
    };
  },
  async created() {
    try {
      const { data } = await getEmailcheckRegistration(
        this.$route.params["code"]
      );
      localStorage.setItem("role", data.role);
      this.phone_number = data.phone_number;
      this.email_address = data.email_address;
      this.role = localStorage.getItem("role");
      this.provider = data.provider;
      console.log("**********************", data);
      this.type = data.servicePracticeType;
    } catch (error) {
      if (error.response && error.response.status == 422) {
        this.errorPin.push("email already verified");
      } else {
        this.errorPin.push(
          "Your session has expired. Please go back to register again."
        );
      }
    }
  },
  methods: {
    async submitForm() {
      this.errorPin = [];

      if (!this.pinNumber) {
        this.errorPin.push("Pin number required");
        this.pinReq = false;
      } else {
        this.pinReq = true;
        //this.showLoader = true;

        try {
          const { data } = await verifyOtp(
            this.email_address,
            this.phone_number,
            this.pinNumber
          );
          //Perform Success Action
          this.pinReq = true;
          //this.showLoader = true;
          //petOwner
          if (this.role == 1) {
            this.$router.push({
              name: "CompleteYourProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
              },
            });
          }
          //shelter
          if (this.role == 6) {
            this.$router.push({
              name: "ShelterCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
              },
            });
          }
          //dogbreed
          if (this.role == 3) {
            this.$router.push({
              name: "DogBreedCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
              },
            });
          }
          if (this.role == 4) {
            this.$router.push({
              name: "ServiceproviderCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
                serviceType: this.type,
              },
            });
          }
          if (this.role == 5) {
            this.$router.push({
              name: "VeterinarianCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
                practiceType: this.type,
              },
            });
          }
        } catch {
          this.errorPin.push(
            "Incorrect PIN code you still have three attempts left"
          );
        }
      }
    },
    inputPinVal(val) {
      this.pinNumber = val;
    },
  },
};
</script>
